import React, { useState, useEffect } from 'react';
import shuffle from 'lodash.shuffle';
import * as styles from './carousel.module.css';

const Circle = ({ size = 24, stroke, strokeWidth, fill }) => (
  <svg height={size} width={size}>
    <circle
      cx={size / 2}
      cy={size / 2}
      r={size / 2}
      stroke={stroke}
      stroke-width={strokeWidth}
      fill={fill}
    />
  </svg>
);

const Carousel = ({ children, timeout = 7500, showControls, showCircles, random }) => {
  const [curIndex, setIndex] = useState(0);

  const slides = random ? shuffle(children) : children;

  const nextSlide = () => {
    if (curIndex === slides.length - 1) setIndex(0);
    else setIndex(curIndex + 1);
  };

  const prevSlide = () => {
    if (curIndex === 0) setIndex(slides.length - 1);
    else setIndex(curIndex - 1);
  };

  const gotoSlide = nextIndex => {
    setIndex(nextIndex);
  };

  useEffect(() => {
    const timer = setInterval(() => nextSlide(), timeout);
    return () => clearTimeout(timer);
  }, [nextSlide]);

  return (
    <div className={styles.maxWidth}>
      <div className={styles.slideWrapper}>{slides[curIndex]}</div>
      {showControls && (
        <div>
          <button onClick={prevSlide}>Prev</button> | <button onClick={nextSlide}>NEXT</button>
        </div>
      )}
      <div className={styles.circles}>
        {showCircles &&
          slides.map((_, i) => (
            <button className={styles.circleButton} onClick={() => gotoSlide(i)}>
              <Circle fill={i === curIndex ? 'black' : 'grey'} size={18} />
            </button>
          ))}
      </div>
    </div>
  );
};

export default Carousel;
