import React from 'react';

import arrowLogo from './icon/south-black-24dp.svg';
import * as styles from './hero-container.module.css';

const HeroContainer = ({ children }) => {
  return (
    <div className={styles.root}>
      <div className={styles.contentBox}>{children}</div>
      <div className={styles.arrowBox}>
        <img src={arrowLogo} alt="arrow" className={styles.arrow} />
      </div>
    </div>
  );
};

export default HeroContainer;
