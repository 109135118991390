import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../lib/helpers';
import { buildImageObj } from '../lib/helpers';
import { imageUrlFor } from '../lib/image-url';
import Layout from '../containers/layout';
import SEO from '../components/seo';
import HeroContainer from '../components/hero-container';
import Carousel from '../components/carousel';

const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      carouselImages {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
        }
        alt
      }
    }
    projects: allSanitySampleProject(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`;

const IndexPage = () => {

  const data = useStaticQuery(query);

  const site = (data || {}).site;
  const carouselImages = site.carouselImages || [];
  const projectNodes = (data || {}).projects
    ? mapEdgesToNodes(data.projects)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout logoVersion="A">
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <h1 hidden>{site.title}</h1>
      <HeroContainer>
        <Carousel random={false} timeout={4000} showCircles={false}>
          {carouselImages &&
            carouselImages.map(carouselImage => {
              return (
                <img
                  key={carouselImage.alt}
                  src={imageUrlFor(buildImageObj(carouselImage))
                    .width(1600)
                    .url()}
                  alt={carouselImage.alt}
                />
              );
            })}
        </Carousel>
      </HeroContainer>
      {/*projectNodes && (
          <ProjectPreviewGrid
            title='Latest projects'
            nodes={projectNodes}
            browseMoreHref='/archive/'
          />
        )*/}
    </Layout>
  );
};

export default IndexPage;
